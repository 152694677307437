function getGeoPointQuery(s, bvalue, bvaluelat, bvaluelng, work) {
  var queryParams = {
    method: 'post',
    url: ajax_url,
    data: 'action=map_point&ville=' + s + '&best_value=' + bvalue + '&best_value_lat=' + bvaluelat + '&best_value_lng=' + bvaluelng + '&besoin=' + work,
  };

  axios(queryParams)
    .then(function (response) {
      // handle success
      centerMapInView();
      setMap(response.data.best_value_lat, response.data.best_value_lng);
      closePopup();

      if (response.data.nearest_marker.length > 0) {
        var boundsCoords = [
          [response.data.best_value_lat, response.data.best_value_lng],
          [response.data.nearest_marker[0].lat, response.data.nearest_marker[0].lng]
        ];
        setBounds(boundsCoords);

        //ici
        setInputValue('#search-box-agence-val', bvalue, response.data.best_value_lat, response.data.best_value_lng);

      }
    })
    .catch(function (error) {
      // handle error
      console.error(error);
    });
}

function getFirstGeoPointQuery(val) {
  if (document.querySelector('#search-box-agence-val') != null || document.querySelector('#search-box-agence-val') != undefined) {

    var apiGouvUrl = 'https://api-adresse.data.gouv.fr/search/';
    var queryParams = {
      method: 'get',
      url: apiGouvUrl,
      params: {
        q: val,
        limit: 1,
        type: 'municipality',
        autocomplete: 1
      }
    };

    axios(queryParams)
      .then(function (response) {
        // handle success
        return {
          lat: response.data.features[0].geometry.coordinates[1],
          lng: response.data.features[0].geometry.coordinates[0]
        };
      })
      .then(function(result){
        getGeoPostQuery(result.lat, result.lng);
        return {
          lat: result.lat,
          lng: result.lng
        };
      })
      .then(function(res) {
        setMap(res.lat, res.lng);
        getReverseGeo(res.lat, res.lng);
        setInputValue('#search-box-agence-val', val, res.lat, res.lng);
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      });
  }
}

function setInputValue(inputClass, label, lat, lng) {
  var inp = document.querySelector(inputClass);
  if (inp != undefined || inp != null) {
    inp.value = label;
    inp.dataset.bestValue = label;
    inp.dataset.bestValueLat = lat;
    inp.dataset.bestValueLng = lng;

    localStorage.setItem('agencySearch', JSON.stringify({
      bvalue: label,
      bvalueLat: lat,
      bvalueLng: lng,
    }));
  }
}

function getUrlVars() {
  var vars = {};
  var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
      vars[key] = value;
  });
  return vars;
}

window.addEventListener("DOMContentLoaded", function() {
  var searchBtnAgency = document.getElementById('search-box-agence-button');
  var searchValueAgency = document.getElementById('search-box-agence-val');
  var searchWorkAgency = document.getElementById('search-box-agence-metier');

  if (searchBtnAgency && searchValueAgency && searchWorkAgency) {
    searchValueAgency.addEventListener('keydown', function(e) {
      if (e.keyCode == 13) {
        /*If the ENTER key is pressed, prevent the form from being submitted,*/
        if (document.body.classList.contains('home')) {
          document.getElementById('search-box-agence').action + '?ville=' + searchValueAgency.dataset.bestValue + '&besoin=' + searchWorkAgency.value;
          setTimeout(function(){
            document.getElementById('search-box-agence').submit();
          }, 50)
        } else {
          e.preventDefault();
          history.pushState({}, "Le réseau | SOPREMA Entreprises", document.getElementById('search-box-agence').action + '?ville=' + searchValueAgency.value + '&besoin=' + searchWorkAgency.value);
          var bestWorkAgency = searchWorkAgency.value;
          var bestValueAgency = searchValueAgency.dataset.bestValue || JSON.parse(localStorage.getItem('agencySearch')).bvalue;
          var bestValueLatAgency = searchValueAgency.dataset.bestValueLat || JSON.parse(localStorage.getItem('agencySearch')).bvalueLat;
          var bestValueLngAgency = searchValueAgency.dataset.bestValueLng || JSON.parse(localStorage.getItem('agencySearch')).bvalueLng;
          getAllPointsQuery();
          getGeoPointQuery(searchValueAgency.value, bestValueAgency, bestValueLatAgency, bestValueLngAgency, bestWorkAgency, bestWorkAgency);
          searchValueAgency.parentNode.removeChild(document.querySelector('#'+searchValueAgency.id+'autocomplete-list'));
          searchValueAgency.blur();
      }
    }
  });

    searchBtnAgency.addEventListener('click', function(e) {
      e.preventDefault();

      if ( searchValueAgency.value !== '' ) {
        if (document.body.classList.contains('home') || document.body.classList.contains('page-template-page-offres-emploi') || document.body.classList.contains('single')) {
          document.getElementById('search-box-agence').submit();
        } else {
          e.preventDefault();
          history.pushState({}, "Le réseau | SOPREMA Entreprises", document.getElementById('search-box-agence').action + '?ville=' + searchValueAgency.value + '&besoin=' + searchWorkAgency.value);
          var bestWorkAgency = searchWorkAgency.value;
          var bestValueAgency = searchValueAgency.dataset.bestValue || JSON.parse(localStorage.getItem('agencySearch')).bvalue;
          var bestValueLatAgency = searchValueAgency.dataset.bestValueLat || JSON.parse(localStorage.getItem('agencySearch')).bvalueLat;
          var bestValueLngAgency = searchValueAgency.dataset.bestValueLng || JSON.parse(localStorage.getItem('agencySearch')).bvalueLng;
          getAllPointsQuery();
          getGeoPointQuery(searchValueAgency.value, bestValueAgency, bestValueLatAgency, bestValueLngAgency, bestWorkAgency, bestWorkAgency);
        }
      } else {
        searchValueAgency.focus();
      }
    });
  }

  if (document.body.classList.contains('page-template-page-reseau') && window.location.href.includes('?ville=') && localStorage.getItem('agencySearch') !== null && JSON.parse(localStorage.getItem('agencySearch')).bvalue.toLowerCase() === getUrlVars().ville.toLowerCase()) {
    var bestWorkAgency = searchWorkAgency.value;
    var bestValueAgency = getUrlVars().ville || searchValueAgency.dataset.bestValue || JSON.parse(localStorage.getItem('agencySearch')).bvalue;
    var bestValueLatAgency = searchValueAgency.dataset.bestValueLat || JSON.parse(localStorage.getItem('agencySearch')).bvalueLat;
    var bestValueLngAgency = searchValueAgency.dataset.bestValueLng || JSON.parse(localStorage.getItem('agencySearch')).bvalueLng;
    setInputValue('#search-box-agence-val', bestValueAgency, bestValueLatAgency, bestValueLngAgency);
    getGeoPointQuery(searchValueAgency.value, bestValueAgency, bestValueLatAgency, bestValueLngAgency, bestWorkAgency, bestWorkAgency);
  } else {
    var city = getUrlVars().ville;
    if ((city != undefined || city != null) && city != '') {
      getFirstGeoPointQuery(city);
    }
  }
});

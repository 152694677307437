L.Map.prototype.panToOffset = function (latlng, offset, options) {
    var x = this.latLngToContainerPoint(latlng).x - offset[0];
    var y = this.latLngToContainerPoint(latlng).y - offset[1];
    var point = this.containerPointToLatLng([x, y]);
    return this.setView(point, this._zoom, { pan: options });
};

L.Map.prototype.setViewOffset = function (latlng, offset, targetZoom) {
    var targetPoint = this.project(latlng, targetZoom).subtract(offset),
        targetLatLng = this.unproject(targetPoint, targetZoom);
    return this.setView(targetLatLng, targetZoom);
};

if (!String.prototype.includes) {
  String.prototype.includes = function(search, start) {
    'use strict';

    if (search instanceof RegExp) {
      throw TypeError('first argument must not be a RegExp');
    }
    if (start === undefined) { start = 0; }
    return this.indexOf(search, start) !== -1;
  };
}

/**
 * GEOLOCATION
 */

var mapResult = null;
var markerResult = null;
var ajax_url = php_to_js.ajaxUrl;
var url = '';
var apiGouvUrl = 'https://api-adresse.data.gouv.fr/search/';
var apiGouvReverseUrl = 'https://api-adresse.data.gouv.fr/reverse/';
var groupSearchMarkers = '';
let groupMarkers = '';

function setAdressValue(inputClass, address) {
  var input = document.querySelector(inputClass);
  if (input) {
    input.value = address;
  }
}

function getReverseGeo(lat, lng) {
  var queryParams = {
    method: 'get',
    url: apiGouvReverseUrl,
    params: {
      lat: lat,
      lon: lng,
      type: 'street'
    }
  };

  axios(queryParams)
    .then(function (response) {
      // handle success
    var city = getUrlVars().ville;
    setInputValue('#search-box-agence-val', city || response.data.features[0].properties.city, response.data.features[0].geometry.coordinates[1], response.data.features[0].geometry.coordinates[0]);

    })
    .catch(function (error) {
      // handle error
      console.error(error);
    });

}

function getGeoPostQuery(lat, lng) {
  var queryParams = {
    method: 'post',
    url: ajax_url,
    data: 'action=map&geoloc_lat='+lat+'&geoloc_lng='+lng
  };

  axios(queryParams)
    .then(function (response) {
      // handle success
      if (response.data.nearest_marker.length > 0) {
        var boundsCoords = [
          [response.data.lat, response.data.lng],
          [response.data.nearest_marker[0].lat, response.data.nearest_marker[0].lng]
        ];
        closePopup();
        centerMapInView('.bloc-search');
        setBounds(boundsCoords);
      }
    })
    .catch(function (error) {
      // handle error
      console.error(error);
    });
}

function getAllPointsQuery() {
  var work = document.querySelector('#search-box-agence-metier').value;
  var queryParams = {
    "method": 'post',
    "url": ajax_url,
    "data": `action=all_points_map&besoin=${work}`,
  };

  axios(queryParams)
    .then(function (response) {
      // handle success
      // if (response.data.length > 0) {
        markerResult = response.data;
        setAllPoint(response.data);
      // }
    })
    .catch(function (error) {
      console.error(error);
    });
}

function geoFindMe(){
  getLocation();
  return false;
}

function recordPosition(position) {
  // GET POSITION LAT / LNG
  var latitude  = position.coords.latitude;
  var longitude = position.coords.longitude;

  // SET USER GEOLOC IN LOCALSTORAGE
  localStorage.setItem('userGeolocation', JSON.stringify({lat: latitude, lng: longitude}));

  if (document.body.classList.contains('home')) {
    window.location.href = document.getElementById('search-box-agence').getAttribute('data-geoloc');
  } else {
    getGeoPostQuery(latitude, longitude);
    getReverseGeo(latitude, longitude);
    setMap(latitude, longitude);
    history.pushState({}, "Le réseau | SOPREMA Entreprises", document.getElementById('search-box-agence').getAttribute('data-geoloc'));
  }

}

function getLocation() {
  if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(getLocation_success, getLocation_error);
  } else {
      alert('La géolocalisation n\'est pas supportée par votre navigateur');
  }
}

function getLocation_success(pos){
  recordPosition(pos);
}

function getLocation_error(err){
  console.warn('ERROR(' + err.code + '): ' + err.message);
}

function setMap(lat, lng) {
  if (mapResult === null) {
    initMap();
  } else {
    // mapResult.setView([lat, lng], 11);
    var userMarker = L.marker([lat, lng], {
      icon: L.icon({
                    iconUrl: url+"/wp-content/themes/soprema/assets/images/ico-pin-locate.svg",
                      iconSize: [20, 22],
                      // iconAnchor: [28, 66],
                      // popupAnchor: [0, -66]
                  })
    });
    centerMapInView('.bloc-search');
    groupSearchMarkers.clearLayers();
    groupSearchMarkers.addLayer( userMarker );
  }
}

function setBounds(arrayBounds) {
    if (arrayBounds.length > 0) {
      if (mapResult === null) {
        initMap();
      } else {
        mapResult.fitBounds(arrayBounds, {padding:[60, 60], maxZoom: 11});
    }
  }
}


// Récupérer la position x et y d'un element dans la page
function getPosition(el) {
  var xPos = 0;
  var yPos = 0;

  while (el) {
    if (el.tagName == "BODY") {
      // deal with browser quirks with body/window/document and page scroll
      var xScroll = el.scrollLeft || document.documentElement.scrollLeft;
      var yScroll = el.scrollTop || document.documentElement.scrollTop;

      xPos += (el.offsetLeft - xScroll + el.clientLeft);
      yPos += (el.offsetTop - yScroll + el.clientTop);
    } else {
      // for all other non-BODY elements
      xPos += (el.offsetLeft - el.scrollLeft + el.clientLeft);
      yPos += (el.offsetTop - el.scrollTop + el.clientTop);
    }

    el = el.offsetParent;
  }
  return {
    x: xPos,
    y: yPos
  };
}

var posBoxSearch = getPosition(document.querySelector('.map-and-popup'));


function centerMapInView() {
  if (posBoxSearch) {
    window.scrollTo({behavior: 'smooth', top: posBoxSearch.y - 15});
  }
}

function setAllPoint(markersData) {
    if (mapResult === null) {
      initMap();
    } else {
      var posIcon = 0;
      if (window.innerWidth < 576) {
        posIcon = -176;
      } else if (window.innerWidth >= 576 && window.innerWidth < 992) {
        posIcon = -190;
      } else {
        posIcon = -220;
      }
      var icon = {
          'agence':
              L.icon({
                  iconUrl: url+"/wp-content/themes/soprema/assets/images/pin-soprema-agence.png",
                  iconSize: [58, 68],
                  iconAnchor: [29, 68],
                  popupAnchor: [posIcon, 0]
              }),
          // 'filiale':
          //     L.icon({
          //         iconUrl: url+"/wp-content/themes/soprema/assets/images/pin-soprema-filiale.png",
          //         iconSize: [56, 66],
          //         iconAnchor: [28, 66],
          //         popupAnchor: [-235, 10]
          //     })
      };
      if (typeof groupMarkers !== 'undefined') {
        groupMarkers.clearLayers();
      }
      var groupMarkers = L.layerGroup();

      markersData.forEach(function(marker){
          // var newMarker = L.marker( L.latLng( marker.location.markers[0].lat, marker.location.markers[0].lng ), { icon : icon[marker.marker_type] } ).on('click', clickZoom).addTo(mapResult);
        var popup = L.popup().setContent(marker.popup);
        var newMarker = L.marker(
          L.latLng( marker.location.markers[0].lat, marker.location.markers[0].lng ),
          { icon : icon.agence })
          .bindPopup(popup).openPopup()
          .on('click', clickZoom)
        groupMarkers.addLayer( newMarker );
      });
      groupMarkers.addTo(mapResult);
    }
}

function clickZoom(e) {
  // insertPopup(e.target.getLatLng());
  if (window.innerWidth < 576) {
    mapResult.setViewOffset(e.target.getLatLng(),[140,160], mapResult.getZoom());
  } else if (window.innerWidth >= 576 && window.innerWidth < 992) {
    mapResult.setViewOffset(e.target.getLatLng(),[70,160], mapResult.getZoom());
  } else {
    mapResult.setViewOffset(e.target.getLatLng(),[0,100], mapResult.getZoom());
  }
}

// function insertPopup(coords) {
//   if (markerResult.length > 0) {
//     var popUp = document.querySelector('.leaflet-popup-content-wrapper');
//     markerResult.forEach(function(itemResult) {
//       if (itemResult.location.markers[0].lat == coords.lat && itemResult.location.markers[0].lng == coords.lng ) {
//           popUp.classList.add('popup-open');
//           document.querySelector('.leaflet-popup-content').innerHTML = itemResult.popup;
//       }
//     });
//   }
// }

function closePopup() {
  var popUp = document.querySelector('.leaflet-popup-content-wrapper');
  if (popUp) {
    popUp.classList.remove('popup-open');
  }
}

function initMap() {
  var mapEl = document.getElementById('result-map');
  if (mapEl) {
    // On initialise la latitude et la longitude de Paris (centre de la carte)
    var lat = 46.940328;
    var lon = 2.890619;
    // Fonction d'initialisation de la carte
    // Créer l'objet "macarte" et l'insèrer dans l'élément HTML qui a l'ID "map"
    mapResult = L.map(mapEl, {zoomControl: false}).setView([lat, lon], 6);
    groupMarkers = L.layerGroup().addTo(mapResult);
    groupSearchMarkers = L.layerGroup().addTo(mapResult);
    // Leaflet ne récupère pas les cartes (tiles) sur un serveur par défaut. Nous devons lui préciser où nous souhaitons les récupérer. Ici, openstreetmap.fr
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        // Il est toujours bien de laisser le lien vers la source des données
        attribution: '<a href="//openstreetmap.fr">OpenStreetMap</a>',
        minZoom: 4,
        maxZoom: 18
    }).addTo(mapResult);
    L.control.zoom({
      position: 'bottomright'
    }).addTo(mapResult);
    mapResult.scrollWheelZoom.disable();
  }
}

function initAgencyMap(mapClass, zoom) {
  var mapEl = document.querySelector(mapClass);
  if (mapEl) {
    // On initialise la latitude et la longitude de Paris (centre de la carte)
    // Fonction d'initialisation de la carte
    // Créer l'objet "macarte" et l'insèrer dans l'élément HTML qui a l'ID "map"
    var lat = mapEl.dataset.lat;
    var lng = mapEl.dataset.lng;
    var mapAgency = L.map(mapEl, {zoomControl: false}).setView([lat, lng], zoom);

    // Leaflet ne récupère pas les cartes (tiles) sur un serveur par défaut. Nous devons lui préciser où nous souhaitons les récupérer. Ici, openstreetmap.fr
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        // Il est toujours bien de laisser le lien vers la source des données
        attribution: '<a href="//openstreetmap.fr">OpenStreetMap</a>',
        minZoom: 4,
        maxZoom: 18
    }).addTo(mapAgency);
    L.control.zoom({
      position: 'bottomright'
    }).addTo(mapAgency);
    mapAgency.scrollWheelZoom.disable();

    var groupAgencyMarker = L.layerGroup().addTo(mapAgency);
    var agencyMarker = L.marker([lat, lng], {
      icon: L.icon({
                    iconUrl: url+"/wp-content/themes/soprema/assets/images/pin-soprema-agence.png",
                    iconSize: [56, 66],
                  })
    });
    groupAgencyMarker.addLayer( agencyMarker );
  }
}

function checkUrl() {
  if (window.location.href.includes('php74.localhost')) {
    url = '//php74.localhost/soprema-entreprises';
  } else if (window.location.href.includes('localhost')) {
    url = '//localhost/Advisa/soprema-entreprises';
  } else if(window.location.href.includes('jerome.dv.sbg')) {
    url = '//www.soprema-entreprises.fr.jerome.dv.sbg.advisa.fr';
  } else if(window.location.href.includes('validation.php70')) {
    url = 'https://soprema-entreprises.validation.php70.sbg.advisa.fr';
  } else if(window.location.href.includes('val.soprema')) {
    url = 'https://val.soprema-entreprises.fr';
  }else {
    url = 'https://www.soprema-entreprises.fr';
  }
}

window.addEventListener("DOMContentLoaded", function() {
  var mapEl = document.getElementById('result-map');
  checkUrl();
  var inputSearch = document.querySelector('#search-box-agence-val');
  if (inputSearch) {
    autocomplete(inputSearch);
    document.querySelector('.find-me').addEventListener('click', function() {
      getAllPointsQuery();
      geoFindMe();
    });
  }
  if (mapEl) {
    initMap();
    getAllPointsQuery();
    if (window.location.href.includes('action=geolocalisation')) {
      geoFindMe();
      centerMapInView('.bloc-search');
    }
  }

  var closePopupBtn = document.querySelector('.close-popup');
  if (closePopupBtn) {
    closePopupBtn.addEventListener('click' , function() {
      closePopup();
    });
  }

  initAgencyMap('.agence-map', 12);

});
